.container {
	max-width: 100%;
	margin: auto;
	
	padding-left: @page-side-padding;
	padding-right: @page-side-padding;
	
	.desktop({
		width: @container-width;
		padding-left: 0;
		padding-right: 0;
	});
}

.container-wrapper {
	padding-left: @page-side-padding;
	padding-right: @page-side-padding;

	.container {
		padding-left: 0px;
		padding-right: 0px;
			
	}
}

.full-back {
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
}

.ratio(@ratio) {
	&:before {
		content: '\00a0';
		display: inline-block;
		width: 0;
		font-size: 0;
		height: 0;
		padding-top: @ratio / 2 * 100%;
		padding-bottom: @ratio / 2 * 100%;
		vertical-align: middle;
	}
}


.pull-left {
	float: left;
}

.pull-right {
	float: right;
}

.center {
	text-align: center;
}

.full-width {
	width: 100%;
}


.clear {
	clear: both;
}

.clear-inner {
    &:after {
        content: '';
        display: table;
        clear: both;
    }
}

.smaller {
    font-size: 75%;
}

.bigger {
    font-size: 125%;
}


.background-color-fade(@color, @opamount) {
    background-color: @color;
    background-color: fade(@color, @opamount);
}

.horz-back-grad(@left, @right) {
  background: -webkit-linear-gradient(left, @left , @right); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(right, @left , @right); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(right, @left , @right); /* For Firefox 3.6 to 15 */
  background: linear-gradient(to right, @left , @right); /* Standard syntax */
}

.background-color-fade(@color, @opamount) {
    background-color: @color;
    background-color: fade(@color, @opamount);
}
.nowrap {
    white-space: nowrap;
}


@media (max-width: @container-width) {
	.only-desktop {
		display: none;
	}
}

.desktop(@rules) {
	.ie6, .ie7, .ie8 {
		@rules();
	}
	
	@media(min-width: @container-width) {
		@rules();
	}
}

.desktop-and-smaller(@rules) {
    @media (max-width: @container-width) {
        @rules();
    }
}

.tablet(@rules) {
	.ie6, ie7, ie8 {
		@rules();
	}
	
	@media(min-width: @tablet-width) {
		@rules();
	}
}

.tablet-and-smaller(@rules) {
    @media (max-width: @tablet-width) {
        @rules();
    }
}

.small-tablet(@rules) {
	@media(min-width: @tablet-small-width) {
		@rules();
	}
}

.small-tablet-and-smaller(@rules) {
	@media(max-width: @tablet-small-width) {
		@rules();
	}
}


.big-phone-and-smaller(@rules) {
    @media (max-width: @big-phone-width) {
        @rules();
    }
}

.big-phone(@rules) {
	@media(min-width: @big-phone-width) {
		@rules();
	}
}

.phone-and-smaller(@rules) {
    @media (max-width: @phone-width) {
        @rules();
    }
}

.phone(@rules) {
	@media(min-width: @phone-width) {
		@rules();
	}
}

.iphone-landscape-and-smaller(@rules) {
    @media (max-width: @iphone-4-landscape-width) {
        @rules();
    }
}

.iphone-landscape(@rules) {
	@media(min-width: @iphone-4-landscape-width) {
		@rules();
	}
}



.big-phone-and-smaller({
    .too-big-for-phone {
        display: none;
    }
});

.primative(@rules) {
	.ie6, .ie7, .ie8 {
		@rules();
	}
}


