// @import (inline) "../node_modules/bootstrap/dist/css/bootstrap.min.css";


@import "definitions";

.app-page {
	@import "../node_modules/ei-form-styles-1/less/ei-form-styles-1";
	@import (less) "../node_modules/ei-grid-1/less/grid";
	@import "../node_modules/@dankolz/webhandle-jobs/less/jobs";
	@import "../node_modules/webhandle-calendar/less/webhandle-calendar/events-app";
	@import "../node_modules/@dankolz/sales-catalog/less/catalog";

	@import (less) "../node_modules/ei-slideshow-1/less/slideshow";

	.slide-tile.grid {
		.grid {
			#ei-grid-1.gutter-grid(10px);

		}
	}
}


.page-artist-data {
	.items-table.sortable {
		@import "../node_modules/@dankolz/tablesort/less/tablesort.less";
	}

	.inv-table {
		.selected-set {
			margin-bottom: 20px;

			a {
				display: inline-block;
				color: black;
				text-decoration: none;
				font-size: 80%;
				margin-right: 20px;
				padding: 5px 10px;

				&.current {
					border-radius: 4px;
					background-color: @drop-down-tan;
				}

			}
		}
	}
}

@import "../node_modules/@dankolz/ei-people-groups-1/less/people-groups";
@import "../node_modules/webhandle-page-editor/less/app";
@import "app-specific";

@import "../node_modules/@webhandle/event-notification-panel/less/components.less";
@import "../node_modules/@webhandle/tree-file-browser/less/components.less";
