
@import (reference) "basics";
.page-main-menu {
	.main-menu-block {
		.main-menu {
			list-style: none;

			a {
				text-decoration: none;
				color          : inherit;

				h3 {
					margin-bottom: 0;
				}

				p {
					margin-top: 0;
				}

			}
		}
	}

}

.page-wrapper {
	header {
		position: relative;
	}
}

.page-sales-console {
	a.grey-button {
		cursor: pointer;
	}
	
	#payment-form {
		margin-top: 20px;
		&.submitting {
			input, button {
				opacity: .2;
			}
		}
		
		button {
			background-color: @blue-text;
			margin-top: 10px;
		}

		button:disabled {
			background-color: @grey-box-background;
		}
	}
	.sales-header {
		display              : grid;
		font-weight          : 600;
		grid-template-columns: auto;

		.tablet({
			grid-template-columns: auto auto;

			.logo {
				justify-self: right;
			}
		});
		label {
			display      : inline-block;
			padding-right: 30px;
			font-weight  : 400;
		}

		.logo {
			width       : 216px;
		}
	}

	.items-actions {
		margin-top   : 10px;
		margin-bottom: 20px;

	}
	.discount-table {
		width: 100%;
		margin-bottom: 50px;
		.field {
			text-align: right;
			width: 240px;
		}
		.discount {
			input {
				display: inline-block;
				width: 200px;
			}
			span {
				display: inline-block;
				width: 30px;
				padding-left: 10px;
				cursor: pointer;
			}
		}
	}

	.items-table {
		margin-bottom: 10px;

		table {
			width: 100%;
		}

		thead {
			background-color: @grey-box-background;
			padding         : 5px 0;

			font-weight: 600;
		}

		td {
			padding: 3px 9px;
		}

		tbody {
			margin-top: 5px;

			tr:nth-child(2n + 1) {
				background-color: @blue-box-background;
			}

			select {
				width           : auto;
				border-color    : transparent;
				background-color: transparent;
			}

			input {
				width           : 80px;
				border-color    : transparent;
				background-color: transparent;
				
				&.discountField {
					width: 120px;
					display: inline-block;
				}
			}
			.line-button {
				display: inline-block;
				margin-left: 5px;
				opacity: 0;
				cursor: pointer;
			}

			.item-actions a {
				opacity   : 0;
				transition: opacity .2s;
			}

			tr:hover {
				.item-actions a {
					opacity: 1;
				}

				input,
				select {
					background-color: #fff;
					border          : 1px solid #ced4da;
				}
				
				.line-button {
					opacity: 1;
				}
			}
			
			
			.overquantity {
				input, input:hover, input:focus {
					background-color: red !important;
					color: white !important;
				}
			}
		}
		tr.summary-row {
			td {
				background-color: @grey-box-background;
			}
		}
		
		.money-right {
			text-align: right;
		}
	}

	@media print {
		.artist-report {
			page-break-after: always;
		}
	}


	.customer-total {
		display              : grid;
		grid-template-columns: 1fr;

		.tablet({
			grid-template-columns: 1fr 1fr;
			column-gap           : 30px;


		});


		.total {
			padding         : 20px 0;
			background-color: @grey-box-background;

			.total-top {
				display              : grid;
				grid-template-columns: auto 1fr;
				column-gap           : 10px;
				margin               : 10px 20px;
				align-items          : center;

				label {
					font-weight  : 600;
					margin-top   : 10px;
					margin-bottom: 10px;
				}

				div {
					text-align: right;

					select {
						width  : auto;
						display: inline-block;
					}
				}
				
			}
			.actions {
				clear: both;
				margin: 0 10px;
				
				a {
					cursor: pointer;
				}
			}


			.grand-total {
				background-color: @blue-box-background;
				border          : solid 2px @blue-box-border;
				.fs(30);
				line-height: 1.5em;
				font-weight: 600;

			}
		}

		.customer {
			.actions {
				display              : grid;
				grid-template-columns: auto auto;
			}

			.edit-action {
				text-align: right;
			}

		}
	}

	.order-actions {
		text-align: right;
		margin-top: 30px;
		
	}
}
.payment-type {
	label {
		display    : inline-block;
		white-space: nowrap;
		vertical-align: bottom;
		margin-left: 10px;
		cursor: pointer;

		.txt {
			position: absolute;
			left    : -10000px;
		}

		input[type=radio] {
			display         : inline-block;
			// padding-right: 10px;
			vertical-align  : bottom;
			width           : auto;
		}

		img {
			display       : inline-block;
			vertical-align: middle;
		}
	}
}

.color-button {
	.fs(18);
	display        : inline-block;
	border-radius  : 3px;
	border         : 0;
	text-align     : center;
	padding        : 9px 12px;
	min-width      : 80px;
	box-sizing     : border-box;
	color          : white;
	font-weight    : 600;
	text-decoration: none;


}

.green-button {
	&:extend(.color-button);
	background-color: @green-button-background;
}

.grey-button {
	&:extend(.color-button);
	background-color: @grey-button-background;

	&.dark {
		color: black;
	}
}

.blue-button {
	&:extend(.color-button);
	background-color: @blue-box-border;
}

.green-button,
.grey-button,
.blue-button {
	img {
		padding-right : 12px;
		vertical-align: sub;
	}
}

.choose-product-dialog-content {
	.products {
		max-height: 500px;
		overflow  : auto;

		table {
			width: 100%;

			tr td {
				cursor : pointer;
				padding: 4px;
			}
		}
	}
}

.choose-customer-dialog-content {
	.customers {
		max-height: 500px;
		overflow  : auto;

		table {
			width: 100%;

			tr td {
				cursor : pointer;
				padding: 4px;
			}
		}
	}
}

.cur-dialog-form {
	.contact-box {
		display              : grid;
		grid-template-columns: 1fr 1fr;
		column-gap           : 14px;

		.double {
			grid-column: span 2;
		}
	}

}


.krom-dialog.dialog-frame {
	.the-dialog {
		border-radius: 0;

		.head {
			border-bottom: 0;
			border-top   : solid 15px @tan;
		}

		.foot {
			border-top: 0;

			.btn-primary {
				.color-button;
				.green-button;
			}

			.btn-cancel {
				.color-button;
				background-color: @red-button-background;
			}
		}

		.btn-close {
			top             : 20px;
			right           : 10px;
			background-color: @grey-box-background;
			text-transform  : uppercase;
			line-height     : .6em;
		}
	}

}

.content-box {
	.contact-box {
		display              : grid;
		column-gap           : 10px;
		grid-template-columns: 2fr 3fr;
		.fs(20);

		.small-cap {
			text-transform: uppercase;
			font-size     : 70%;
			font-weight   : 600;
		}

		.double {
			grid-column: span 2;
		}

		input:disabled,
		textarea:disabled {
			background-color: @grey-box-background;
		}
	}
}

.comments-box {
	margin-top: 30px;

	[name=privateComments] {
		display: none;
	}
}


.artist-bio {
	h2 {
		color      : @tan;
		font-weight: 400;
	}

	.content-box {
		border-top : solid 2px @tan;
		padding-top: 10px;

		input,
		textarea {
			background-color: @grey-input-back;
		}
	}

}

.inventory-status {
	.artist-header {
		display: grid;
		grid-template-columns: 1fr auto;
		input {
			display: inline-block;
			background-color: @grey-input-back;
			border-radius: 2px;
			width: auto;
		}
		
		.search {
			text-align: right;
			white-space: nowrap;
		}
	}
	
	.inv-table {
		margin-top: 40px;
		
		img {
			width: 50px;
		}
	}
}
.sales-summary-header {
	display: grid;
	grid-template-columns: auto auto;
	align-items: center;
	input {
		display: inline-block;
		background-color: @grey-input-back;
		border-radius: 2px;
		width: 250px;
	}
}
		

.artist-form {
}


.app-page .download-photo-albums {
	.all-option {
		padding-left: 220px;
	}
	label {
		clear: both;
		padding-top: 20px;
	}
	input[type=checkbox] {
		width: auto;
		display: inline-block;
	}
	
	img {
		max-width: 200px;
		float: left;
		margin-right: 20px;
	}
	
	.clear {
		clear: both;
	}
}

@media print {
	.order-actions {
		display: none;
	}
}

.page-missing-skus {
	.missing-sku-order {
		margin: 40px 0;
		table {
			td, th {
				padding: 3px 5px;
			}
		}
	}
	
}

.dreck-listing-1 {
	.actions {
		form {
			display: inline-block;
		}
		a {
			opacity: 0;
		}
		
	}
	tr:hover {
		.actions {
			a {
				opacity: 1;
			}
		}
	}
}

.ei-css-grid {
	.hide {
		display: none;
	}
}

.app-page {
	.sales-catalog-form {
		.secondary-images {
			margin-top: 0;
		}
	}
}
.order-display-raw-data .raw-data {
	display: none;
}

#adjustmentpurchase {
	display: none;
}

.page.advanced {
	#adjustmentpurchase {
		display: inline-block;
	}
	
}
.money-right {
	text-align: right;
}

.payment-actions {
	margin-top: 40px;
	.buttons {
		text-align: right;
		margin-top: 15px;
		margin-left: 10px;
		margin-right: 10px;
		button {
			.grey-button;
			margin-left: 10px;
			
			&.pay {
				background-color: @blue-text;
				color: white;
			}
		}
	}
}

.readers {
	margin-top: 15px;
	
}

.choose-item-dialog {
	.the-dialog {
		// top: 0 !important;
		// transform: translate(-50%, 40px) !important;
	}
}

.phone-number-parts {
	input {
		display: inline-block;
		margin-left: 5px;
		margin-right: 5px;
		width: auto;
	}
}

.webhandle-page-editor-file-browser {
	.view-controls {
		.left {
			display: grid;
			grid-template-columns: 1fr auto;
			column-gap: 20px;
		}
	}
	@media (min-width: @container-width) {
		margin-left: ~"calc(-50vw + " @container-width / 2  ~" + 10px)";
		margin-right: ~"calc(-50vw + " @container-width / 2 ~" + 10px)";

	}
}