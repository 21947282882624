
.setup-width-definitions() {
	@container-width:			1100px;
	@tablet-width: 				900px;
	@tablet-small-width:        716px;
	@big-phone-width:			650px;
	@phone-width:               550px;
	@iphone-4-landscape-width:	480px;
	@iphone-4-width: 			320px;
}

.setup-width-definitions();

@page-side-padding: 			10px;

/*
 * The base font size which makes the chosen font match photoshop.
 * For Open Sans this is 7.1pt, for Source Sans Pro this is 7.6pt 
 */
@base-font-size:			5pt;

@grey-input-back: 			#f1f1f1;
@grey-body-text:			#2e2e2e;
@grey-footer-background: 	#2e2e2e;

@drop-down-tan: 			#e7dfc4;
@galleries-strip-back: 		#ddd0a1;
@drop-down-selected-tan: 	#cebf92;
@tan: 						#cdbb7d;
@brown: 					#9a6d1e;


// for sales console
@grey-box-background: 		#f1f1f1;
@grey-button-background: 	#c9c9c9;
@grey-disabled-text:	 	#c9c9c9;
@grey-box-border: 			#b3b3b3;

@blue-box-background: 		#eaf4fd;
@blue-box-border: 			#97caf5;
@blue-text: 				#2d8bdb;

@green-button-background: 	#a7da66;

@red-button-background: 	#d3321c;




.source-sans-pro() {
	font-family: 'Source Sans Pro', sans-serif;
}

.open-sans() {
    font-family: 'Open Sans', sans-serif;
	font-optical-sizing: auto;
	font-variation-settings: "wdth" 100;
}

.cormorant {
	font-family: 'Cormorant', serif;
	font-family: "Cormorant", serif;
	font-optical-sizing: auto;
}

.menu-link() {
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
}

.link-inherit() {
	
	a {
		.menu-link;
		font-size: inherit;
		color: inherit;
		font-weight: inherit;
		line-height: inherit;
		font-family: inherit;
	}
}

.fs(@font-size) {
	font-size: @base-font-size * @font-size / 10;
}

h1, h2, h3, h4, h5 {
	.link-inherit;
}

body {
	.open-sans;
    color: @grey-body-text;
}
